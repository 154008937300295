import Styled from 'styled-components';

const GlobalStyles = Styled.div`
  font-family: lato, sans-serif;
  font-size: 18px;
  
  .notification-provider__growl-list {

  }
`;

export default GlobalStyles;
